import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Button, Checkbox, Input } from 'antd';

import breakpoints from '../styles/breakpoints';
import { ENTER } from '../constants/keys';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const MainContent = styled.div`
  min-height: 100vh;
  background: rgb(245, 247, 250);
`;

const Card = styled.div`
  max-width: 570px;
  margin: 0 auto;
  padding: 5rem 1rem;
  ${props => props.loading && `pointer-events: none;`}

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 1rem;
  }
`;

const Header = styled.header`
  padding: 2.5rem;
  font-size: 1.375rem;
  font-weight: 500;
  text-align: center;
  color: #343434;
  background-color: #ffffff;
  border-bottom: solid 1px #eceff0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem;
  }
`;

const Body = styled.section`
  padding: 1.5rem 4.375rem 4.375rem;
  text-align: left;
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 2rem 2rem;
  }
`;

const Field = styled.div`
  margin-bottom: 1.5rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 1.5rem 0;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const SignupButton = styled(Button)`
  padding-left: 4.25rem;
  padding-right: 4.25rem;
  margin-right: 1.5rem;

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    margin: 0 0 1rem 0;
    justify-content: center;
  }
`;

const LoginLink = styled(Link)`
  font-weight: 500;

  @media (max-width: ${breakpoints.sm}) {
    align-self: flex-end;
  }
`;

const TermsLink = styled.a`
  display: block;
  margin-top: 5px;
  margin-left: 40px;
`;

const Message = styled.div`
  font-size: 0.875rem;
  margin-top: 1rem;
  color: ${props => (props.error ? '#ff5a54' : '#59c156')};
`;

function SignupPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agreeTerm, setAgreeTerm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const invalid = !email || !password || !passwordConfirm || passwordNotMatch;

  const handleRegister = async () => {
    if (invalid || loading) {
      return;
    }

    try {
      setLoading(true);
      setErrorMessage('');
      setLoading(false);
      setSuccessMessage(
        'Đăng ký tài khoản thành công.<br />Vui lòng <b>kiểm tra hộp thư</b> của bạn để <b>kích hoạt tài khoản</b>.'
      );
      setEmail('');
      setPassword('');
      setPasswordConfirm('');
      setAgreeTerm(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại sau.');
      }
      console.error(error);
    }
  };

  return (
    <Layout mainContent={MainContent}>
      <SEO title="Đăng ký tài khoản" />
      <Card loading={loading}>
        <Header>Đăng ký tài khoản</Header>
        <Body>
          <Field>
            <Input
              label="Email"
              placeholder="Địa chỉ email của bạn"
              value={email}
              onChange={event => setEmail(event.target.value)}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleRegister();
                }
              }}
            />
          </Field>
          <Field>
            <Input
              type="password"
              label="Mật khẩu"
              placeholder="**********"
              value={password}
              hasError={passwordNotMatch}
              message={passwordNotMatch ? 'Mật khẩu không khớp' : ''}
              onChange={event => {
                const { value } = event.target;
                setPassword(value);
                if (passwordConfirm && value !== passwordConfirm) {
                  setPasswordNotMatch(true);
                } else {
                  setPasswordNotMatch(false);
                }
              }}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleRegister();
                }
              }}
            />
          </Field>
          <Field>
            <Input
              type="password"
              label="Nhập lại mật khẩu"
              placeholder="**********"
              value={passwordConfirm}
              hasError={passwordNotMatch}
              message={passwordNotMatch ? 'Nhập lại mật khẩu không khớp' : ''}
              onChange={event => {
                const { value } = event.target;
                setPasswordConfirm(value);
                if (password && value !== password) {
                  setPasswordNotMatch(true);
                } else {
                  setPasswordNotMatch(false);
                }
              }}
              onKeyDown={event => {
                if (event.keyCode === ENTER) {
                  handleRegister();
                }
              }}
            />
          </Field>
          <Checkbox checked={agreeTerm} onClick={() => setAgreeTerm(!agreeTerm)}>
            Tôi đồng ý với các điều khoản của Tính Phí
          </Checkbox>
          <TermsLink href="/dieu-khoan/" target="_blank">
            Xem các điều khoản
          </TermsLink>
          <Actions>
            <SignupButton
              size="large"
              loading={loading}
              disabled={!agreeTerm || invalid || loading}
              onClick={handleRegister}
            >
              Đăng ký
            </SignupButton>
            <LoginLink to="/dang-nhap/">Đăng nhập</LoginLink>
          </Actions>
          <br />
          <br />
          {errorMessage && <Message error>{`* ${errorMessage}`}</Message>}
          {successMessage && (
            <Message
              dangerouslySetInnerHTML={{
                __html: successMessage,
              }}
            />
          )}
        </Body>
      </Card>
    </Layout>
  );
}

SignupPage.propTypes = {};

export default SignupPage;
